import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal, WritableSignal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ServiceToolBaseComponent, ServiceToolFieldTrackingDirective } from '@big-direkt/service-tools/base';
import { UiAccordionItemComponent } from '@big-direkt/ui/accordion';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { ButtonGroupDataModel, UiButtonGroupComponent } from '@big-direkt/ui/button-group';
import { UiFormRowComponent } from '@big-direkt/ui/form-row';
import { UiInputComponent } from '@big-direkt/ui/input';
import { SelectOption, UiSelectComponent } from '@big-direkt/ui/select';
import { ScrollService } from '@big-direkt/utils/environment';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { TranslocoDirective } from '@jsverse/transloco';
import { DiabetesRiskDiagnosisForm } from '../models/diabetes-risk-diagnosis-form.model';
import { DiabetesRiskDiagnosisResultsBlockItem } from '../models/diabetes-risk-diagnosis-results.model';
import { DiabetesDiagnosisService } from '../services/diabetes-diagnosis.service';

@Component({
    selector: 'big-service-tools-diabetes-diagnosis-risk-diagnoses',
    standalone: true,
    templateUrl: './risk-diagnosis.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        provideTranslationScope('stDiabetesDiagnosis', /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`)),
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        ServiceToolFieldTrackingDirective,
        TranslocoDirective,
        UiAccordionItemComponent,
        UiButtonComponent,
        UiButtonGroupComponent,
        UiFormRowComponent,
        UiInputComponent,
        UiSelectComponent,
        JsonPipe,
    ],
})
export class RiskDiagnosisComponent extends ServiceToolBaseComponent<DiabetesRiskDiagnosisForm> {
    private readonly scrollService = inject(ScrollService);
    private readonly diabetesDiagnosisService = inject(DiabetesDiagnosisService);

    public readonly riskBlock: WritableSignal<DiabetesRiskDiagnosisResultsBlockItem | undefined> = signal(undefined);

    public yesNoOptions: ButtonGroupDataModel<boolean>[] = [
        {
            label: 'stDiabetesDiagnosis.label.yes',
            value: true,
        },
        {
            label: 'stDiabetesDiagnosis.label.no',
            value: false,
        },
    ];

    public genderOptions: ButtonGroupDataModel<DiabetesRiskDiagnosisForm['gender']['value']>[] = [
        {
            label: 'stDiabetesDiagnosis.label.genderOptions.male',
            value: 'm',
        },
        {
            label: 'stDiabetesDiagnosis.label.genderOptions.female',
            value: 'f',
        },
    ];

    public bodyWaistSizeOptionsFemale: ButtonGroupDataModel<DiabetesRiskDiagnosisForm['bodyWaistSize']['value']>[] = [
        {
            label: 'stDiabetesDiagnosis.label.bodyWaistSizeOptions.lt80',
            value: 'lt80',
        },
        {
            label: 'stDiabetesDiagnosis.label.bodyWaistSizeOptions.80to88',
            value: '80to88',
        },
        {
            label: 'stDiabetesDiagnosis.label.bodyWaistSizeOptions.gt88',
            value: 'gt88',
        },
    ];

    public bodyWaistSizeOptionsMale: ButtonGroupDataModel<DiabetesRiskDiagnosisForm['bodyWaistSize']['value']>[] = [
        {
            label: 'stDiabetesDiagnosis.label.bodyWaistSizeOptions.lt94',
            value: 'lt94',
        },
        {
            label: 'stDiabetesDiagnosis.label.bodyWaistSizeOptions.94to102',
            value: '94to102',
        },
        {
            label: 'stDiabetesDiagnosis.label.bodyWaistSizeOptions.gt102',
            value: 'gt102',
        },
    ];

    public eatsHighFiberFoodsOptions: ButtonGroupDataModel<DiabetesRiskDiagnosisForm['eatsHighFiberFoods']['value']>[] = [
        {
            label: 'stDiabetesDiagnosis.label.eatsHighFiberFoodsOptions.yes',
            value: true,
        },
        {
            label: 'stDiabetesDiagnosis.label.eatsHighFiberFoodsOptions.no',
            value: false,
        },
    ];

    public ageOptions: SelectOption<DiabetesRiskDiagnosisForm['age']['value']>[] = [
        {
            key: 'label.ageOptions.lt35',
            value: 'lt35',
            scope: 'stDiabetesDiagnosis',
        },
        {
            key: 'label.ageOptions.35to44',
            value: '35to44',
            scope: 'stDiabetesDiagnosis',
        },
        {
            key: 'label.ageOptions.45to54',
            value: '45to54',
            scope: 'stDiabetesDiagnosis',
        },
        {
            key: 'label.ageOptions.55to64',
            value: '55to64',
            scope: 'stDiabetesDiagnosis',
        },
        {
            key: 'label.ageOptions.gt65',
            value: 'gt65',
            scope: 'stDiabetesDiagnosis',
        },
    ];

    public hasRelativesWithDiabetesOptions: SelectOption<DiabetesRiskDiagnosisForm['hasRelativesWithDiabetes']['value']>[] = [
        {
            key: 'label.hasRelativesWithDiabetesOptions.unkown',
            value: 'no',
            scope: 'stDiabetesDiagnosis',
        },
        {
            key: 'label.hasRelativesWithDiabetesOptions.closeRelationship',
            value: 'closeRelationship',
            scope: 'stDiabetesDiagnosis',
        },
        {
            key: 'label.hasRelativesWithDiabetesOptions.distantRelationship',
            value: 'distantRelationship',
            scope: 'stDiabetesDiagnosis',
        },
    ];

    public constructor() {
        super();

        /* eslint-disable @typescript-eslint/unbound-method */
        this.form = this.formBuilder.group({
            gender: new FormControl<DiabetesRiskDiagnosisForm['gender']['value']>(undefined, [Validators.required]),
            age: new FormControl<DiabetesRiskDiagnosisForm['age']['value']>('', [Validators.required]),
            bodySize: new FormControl<DiabetesRiskDiagnosisForm['bodySize']['value']>(undefined, [
                Validators.required,
                Validators.pattern(/^(250|[1-9]\d?|1\d{2}|2[0-4]\d)$/),
            ]),
            bodyWeight: new FormControl<DiabetesRiskDiagnosisForm['bodyWeight']['value']>(undefined, [
                Validators.required,
                Validators.pattern(/^(200|[1-9]\d?|1\d{2})$/),
            ]),
            bodyWaistSize: new FormControl<DiabetesRiskDiagnosisForm['bodyWaistSize']['value']>(undefined, [Validators.required]),
            doesSports: new FormControl<DiabetesRiskDiagnosisForm['doesSports']['value']>(undefined, [Validators.required]),
            eatsHighFiberFoods: new FormControl<DiabetesRiskDiagnosisForm['eatsHighFiberFoods']['value']>(undefined, [Validators.required]),
            isSmoker: new FormControl<DiabetesRiskDiagnosisForm['isSmoker']['value']>(undefined, [Validators.required]),
            highBloodSugarLevels: new FormControl<DiabetesRiskDiagnosisForm['highBloodSugarLevels']['value']>(undefined, [Validators.required]),
            medicationBloodPressure: new FormControl<DiabetesRiskDiagnosisForm['medicationBloodPressure']['value']>(undefined, [Validators.required]),
            hasRelativesWithDiabetes: new FormControl<DiabetesRiskDiagnosisForm['hasRelativesWithDiabetes']['value']>('', [Validators.required]),
        });
        /* eslint-enable @typescript-eslint/unbound-method */
    }

    public submit(event: Event): void {
        event.preventDefault();

        this.hasBeenSubmitted = true;
        this.trackStFormSubmitEvent();

        if (!this.form?.valid) {
            this.scrollService.scroll('big-service-tools-diabetes-diagnosis-risk-diagnoses .ng-invalid');

            return;
        }

        const formControls = this.form.controls;

        const bmi = this.calculateBmi();

        if (!bmi) {
            return;
        }

        const riskPoints = this.diabetesDiagnosisService.getRiskPoints(
            formControls.age.value,
            formControls.hasRelativesWithDiabetes.value,
            formControls.bodyWaistSize.value,
            formControls.doesSports.value,
            formControls.eatsHighFiberFoods.value,
            formControls.medicationBloodPressure.value,
            formControls.highBloodSugarLevels.value,
            bmi,
        );

        const riskBlock = this.diabetesDiagnosisService.getDiagnosisBlock(riskPoints, bmi);
        this.riskBlock.set(riskBlock);

        this.scrollService.scroll('big-service-tools-diabetes-diagnosis-risk-diagnoses');
    }

    private calculateBmi(): number | undefined {
        const formControls = this.form?.controls;
        const toSquare = 2;

        if (!formControls?.bodyWeight.value || !formControls.bodySize.value) {
            return undefined;
        }

        const weight = Number(formControls.bodyWeight.value);
        const sizeInMeter = Number(formControls.bodySize.value) / 100; // eslint-disable-line @typescript-eslint/no-magic-numbers

        if (isNaN(weight) || isNaN(sizeInMeter)) {
            return undefined;
        }

        return weight / sizeInMeter ** toSquare;
    }
}

export default RiskDiagnosisComponent;
