import { Injectable } from '@angular/core';
import { DiabetesRiskDiagnosisForm } from '../models/diabetes-risk-diagnosis-form.model';
import {
    DiabetesRiskDiagnosisResults,
    DiabetesRiskDiagnosisResultsBlock,
    DiabetesRiskDiagnosisResultsBlockItem,
} from '../models/diabetes-risk-diagnosis-results.model';

@Injectable({ providedIn: 'any' })
export class DiabetesDiagnosisService {
    private readonly results: DiabetesRiskDiagnosisResults = {
        under7: {
            bmiLt30: {
                healthCheck: ['health_check_up_35'],
                preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
                treatmentServices: [],
                treatmentServicesSmoker: ['prevention_services_quit_smoking', 'app_quit_smoking'],
            },
            bmiGt30: {
                healthCheck: ['health_check_up_35'],
                preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
                treatmentServices: ['digital_health_application', 'digital_nutrition_therapy'],
                treatmentServicesSmoker: ['prevention_services_quit_smoking', 'app_quit_smoking'],
            },
            bmiGt40: {
                healthCheck: ['health_check_up_35'],
                preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
                treatmentServices: [],
                treatmentServicesSmoker: ['prevention_services_quit_smoking', 'app_quit_smoking'],
            },
        },
        from7to11: {
            bmiLt30: {
                healthCheck: ['health_check_up_35'],
                preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
                treatmentServices: [],
                treatmentServicesSmoker: ['prevention_services_quit_smoking', 'app_quit_smoking'],
            },
            bmiGt30: {
                healthCheck: ['health_check_up_35'],
                preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
                treatmentServices: ['digital_health_application', 'digital_nutrition_therapy'],
                treatmentServicesSmoker: ['prevention_services_quit_smoking', 'app_quit_smoking'],
            },
            bmiGt40: {
                healthCheck: ['health_check_up_35'],
                preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
                treatmentServices: [],
                treatmentServicesSmoker: ['prevention_services_quit_smoking', 'app_quit_smoking'],
            },
        },
        from12to14: {
            bmiLt30: {
                healthCheck: ['doctor_search', 'medical_appointment_service', 'health_check_up_35'],
                preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
                treatmentServices: [],
                treatmentServicesSmoker: ['prevention_services_quit_smoking', 'app_quit_smoking'],
            },
            bmiGt30: {
                healthCheck: ['doctor_search', 'medical_appointment_service', 'health_check_up_35'],
                preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
                treatmentServices: ['digital_health_application', 'digital_nutrition_therapy'],
                treatmentServicesSmoker: ['prevention_services_quit_smoking', 'app_quit_smoking'],
            },
            bmiGt40: {
                healthCheck: ['doctor_search', 'medical_appointment_service', 'health_check_up_35'],
                preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
                treatmentServices: [],
                treatmentServicesSmoker: ['prevention_services_quit_smoking', 'app_quit_smoking'],
            },
        },
        from15to20: {
            bmiLt30: {
                healthCheck: ['doctor_search', 'medical_appointment_service', 'health_check_up_35'],
                preventionServices: [],
                treatmentServices: [],
                treatmentServicesSmoker: [
                    'prevention_services_quit_smoking',
                    'app_quit_smoking',
                    'bigtionaer',
                    'prevention_courses',
                    'sports_medical_check_up',
                ],
            },
            bmiGt30: {
                healthCheck: ['doctor_search', 'medical_appointment_service', 'health_check_up_35'],
                preventionServices: [],
                treatmentServices: [],
                treatmentServicesSmoker: [
                    'prevention_services_quit_smoking',
                    'app_quit_smoking',
                    'bigtionaer',
                    'prevention_courses',
                    'sports_medical_check_up',
                ],
            },
            bmiGt40: {
                healthCheck: ['doctor_search', 'medical_appointment_service', 'health_check_up_35'],
                preventionServices: [],
                treatmentServices: ['digital_health_application', 'digital_nutrition_therapy'],
                treatmentServicesSmoker: [
                    'prevention_services_quit_smoking',
                    'app_quit_smoking',
                    'bigtionaer',
                    'prevention_courses',
                    'sports_medical_check_up',
                ],
            },
        },
        over20: {
            bmiLt30: {
                healthCheck: ['doctor_search', 'medical_appointment_service', 'health_check_up_35', 'family_doctor_care'],
                preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
                treatmentServices: [],
                treatmentServicesSmoker: ['prevention_services_quit_smoking', 'app_quit_smoking'],
            },
            bmiGt30: {
                healthCheck: ['doctor_search', 'medical_appointment_service', 'health_check_up_35', 'family_doctor_care'],
                preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
                treatmentServices: ['digital_health_application', 'digital_nutrition_therapy'],
                treatmentServicesSmoker: ['prevention_services_quit_smoking', 'app_quit_smoking'],
            },
            bmiGt40: {
                healthCheck: ['doctor_search', 'medical_appointment_service', 'health_check_up_35', 'family_doctor_care'],
                preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
                treatmentServices: [],
                treatmentServicesSmoker: ['prevention_services_quit_smoking', 'app_quit_smoking'],
            },
        },
    };

    /* eslint-disable @typescript-eslint/no-magic-numbers */
    // eslint-disable-next-line @typescript-eslint/max-params
    public getRiskPoints(
        age: DiabetesRiskDiagnosisForm['age']['value'],
        hasRelativesWithDiabetes: DiabetesRiskDiagnosisForm['hasRelativesWithDiabetes']['value'],
        bodyWaistSize: DiabetesRiskDiagnosisForm['bodyWaistSize']['value'],
        doesSports: DiabetesRiskDiagnosisForm['doesSports']['value'],
        eatsHighFiberFoods: DiabetesRiskDiagnosisForm['eatsHighFiberFoods']['value'],
        medicationBloodPressure: DiabetesRiskDiagnosisForm['medicationBloodPressure']['value'],
        highBloodSugarLevels: DiabetesRiskDiagnosisForm['highBloodSugarLevels']['value'],
        bmi: number,
    ): number {
        let riskPoints = 0;

        switch (age) {
            case '35to44':
                riskPoints += 1;
                break;
            case '45to54':
                riskPoints += 2;
                break;
            case '55to64':
                riskPoints += 3;
                break;
            case 'gt65':
                riskPoints += 4;
                break;
            default:
                break;
        }

        switch (hasRelativesWithDiabetes) {
            case 'distantRelationship':
                riskPoints += 3;
                break;
            case 'closeRelationship':
                riskPoints += 5;
                break;
            default:
                break;
        }

        switch (bodyWaistSize) {
            case 'lt80':
            case 'lt94':
                riskPoints += 0;
                break;
            case '80to88':
            case '94to102':
                riskPoints += 3;
                break;
            case 'gt88':
            case 'gt102':
                riskPoints += 4;
                break;
            default:
                break;
        }

        if (!doesSports) {
            riskPoints += 2;
        }

        if (!eatsHighFiberFoods) {
            riskPoints += 1;
        }

        if (medicationBloodPressure) {
            riskPoints += 2;
        }

        if (highBloodSugarLevels) {
            riskPoints += 5;
        }

        if (bmi > 25 && bmi < 30) {
            riskPoints += 1;
        } else if (bmi > 30) {
            riskPoints += 3;
        }

        return riskPoints;
    }
    /* eslint-enaable @typescript-eslint/no-magic-numbers */

    public getDiagnosisBlock(
        points: number,
        bmi: number,
    ): DiabetesRiskDiagnosisResultsBlockItem | undefined {
        const riskPoints = this.getPointsRange(points);
        const bmiRange = this.getBmiRange(bmi);

        return this.results[riskPoints][bmiRange];
    }

    private getPointsRange(points: number): keyof DiabetesRiskDiagnosisResults {
        if (points >= 7 && points <= 11) {
            return 'from7to11';
        } else if (points >= 12 && points <= 14) {
            return 'from12to14';
        } else if (points >= 15 && points <= 20) {
            return 'from15to20';
        } else if (points > 20) {
            return 'over20';
        }

        return 'under7';
    }

    private getBmiRange(bmi: number): keyof DiabetesRiskDiagnosisResultsBlock {
        if (bmi > 30 && bmi <= 40) {
            return 'bmiGt30'
        } else if(bmi > 40) {
            return 'bmiGt40';
        }

        return 'bmiLt30';
    }
}
